import React, { ComponentProps } from 'react';
import { userFormField } from '../hooks';
import {
  FormControl,
  FormGroup,
  Checkbox,
  FormControlLabel,
  FormHelperText,
} from '@material-ui/core';
import { FormFieldProps } from '../types';

export const CheckboxField = ({
  label,
  helperText,
  name,
  rules,
  defaultValue = '',
  control,
  ...props
}: FormFieldProps & ComponentProps<typeof Checkbox>) => {
  const {
    field: { ref, value, onChange },
    fieldState: { invalid },
  } = userFormField({
    name,
    rules,
    defaultValue,
    control,
  });

  return (
    <FormControl required={!!rules?.required} error={invalid}>
      <FormGroup row>
        <FormControlLabel
          label={label}
          control={
            <Checkbox
              color="primary"
              checked={!!value}
              onChange={(ev) => {
                onChange(ev.target.checked);
              }}
              inputRef={ref}
              {...props}
            />
          }
        />
      </FormGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
