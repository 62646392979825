import React, { ComponentProps } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { FormHelperText } from '@material-ui/core';
import { FieldError as RHFFieldError, FieldErrors, useFormContext } from 'react-hook-form';
import has from 'lodash/has';
import get from 'lodash/get';

export const FieldError = ({
  name,
  ...props
}: ComponentProps<typeof FormHelperText> & Pick<ComponentProps<typeof ErrorMessage>, 'name'>) => {
  const {
    formState: { errors },
  } = useFormContext();

  if (!has(errors, name)) {
    return null;
  }

  const error = get<RHFFieldError>(errors, name);

  return (
    <FormHelperText error {...props}>
      {getMessage(error)}
    </FormHelperText>
  );
};

const getMessage = (error: RHFFieldError) => {
  if (error.message) {
    return error.message;
  }

  const required = error.type === 'required' || error.types?.required;

  if (required) {
    return 'This field is required';
  }
};
