import React, { ComponentProps } from 'react';
import { useFormContext, UseFormReturn } from 'react-hook-form';
import { Button } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';

export const SubmitButton = ({ children, ...props }: ComponentProps<typeof Button>) => {
  const form = useFormContext();
  const isLoading = form.formState.isSubmitting;
  const isDisabled = isLoading || props.disabled || !form.formState.isDirty;

  if (isLoading) {
    return (
      <LoadingButton loading={isLoading} {...props}>
        {children}
      </LoadingButton>
    );
  }

  return (
    <Button type="submit" {...props} disabled={isDisabled}>
      {children}
    </Button>
  );
};
