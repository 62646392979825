import React from 'react';
import { CheckboxField } from '@app/components/form/fields/CheckboxField';
import { PasswordField } from '@app/components/form/fields/PasswordField';
import { TextField } from '@app/components/form/fields/TextField';
import { Form } from '@app/components/form/Form';
import { SubmitButton } from '@app/components/form/SubmitButton';
import { ExternalLayout } from '@app/components/layouts/External';
import { Link } from '@app/components/Link';
import { Title } from '@app/components/Title';
import { useUser } from '@app/hooks/useUser';
import { withUser } from '@app/propsDecorators/withUser';
import { Box, Paper } from '@material-ui/core';
import nookies from 'nookies';
import { useForm } from 'react-hook-form';
import { FieldError } from '@app/components/form/FieldError';

const LoginPage = ({ redirectTo }: { redirectTo: string }) => {
  const form = useForm<{ username: string; password: string; remember: boolean }>();
  const { login } = useUser();

  return (
    <div>
      <Title>Login</Title>
      <Box
        sx={{ display: 'flex', minHeight: '100vh', justifyContent: 'center', alignItems: 'center' }}
      >
        <Box component={Paper} sx={{ p: 2, width: 400 }}>
          <Form
            form={form}
            onSubmit={async ({ username, password, remember }) => {
              try {
                await login(username, password, redirectTo, remember);
              } catch (error) {
                form.setError('username', {
                  type: 'manual',
                  message: error.message,
                });
              }
            }}
          >
            <Box sx={{ mb: 2 }}>
              <TextField
                variant="outlined"
                fullWidth
                name="username"
                label="Username or email"
                rules={{ required: true }}
              />
              <FieldError name="username" />
            </Box>
            <Box sx={{ mb: 2 }}>
              <PasswordField
                variant="outlined"
                fullWidth
                name="password"
                label="Password"
                rules={{ required: true }}
              />
              <FieldError name="password" />
            </Box>
            <Box
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}
            >
              <CheckboxField name="remember" label="Remember me" />

              <SubmitButton variant="contained" color="primary" size="large">
                Login
              </SubmitButton>
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <Link href="/forgot-password">Forgot password?</Link>
            </Box>
          </Form>
        </Box>
      </Box>
    </div>
  );
};

LoginPage.Layout = ExternalLayout;

export const getServerSideProps = withUser(async ({ user, req, res, query }) => {
  const redirectTo = (query.redirectTo as string) || nookies.get({ req }).redirectTo || '/';

  if (user) {
    if (redirectTo) {
      nookies.destroy({ res }, 'redirectTo');
    }

    return {
      redirect: {
        destination: redirectTo,
        permanent: false,
      },
    };
  } else {
    if (redirectTo) {
      nookies.destroy({ res }, 'redirectTo');
      return {
        props: {
          redirectTo,
        },
      };
    }
  }
});

export default LoginPage;
