import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';

export const Title = ({ children }: { children: ReactNode }) => {
  const title = `${children ? `${children} - ` : ''}Questo Admin`;

  return (
    <Head>
      <title key="title">{title}</title>
    </Head>
  );
};

Title.propTypes = {
  children: PropTypes.string,
};

Title.defaultProps = {
  children: null,
};
