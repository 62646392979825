import React, { ComponentProps } from 'react';
import { TextField as MuiTextField } from '@material-ui/core';
import { userFormField } from '../hooks';
import { FormFieldProps } from '../types';

export const TextField = ({
  name,
  rules,
  defaultValue = '',
  parse,
  format,
  control,
  ...props
}: FormFieldProps &
  ComponentProps<typeof MuiTextField> & {
    parse?: (value: any) => any;
    format?: (value: any) => any;
  }) => {
  const {
    field: { ref, ...fieldProps },
    fieldState: { invalid },
  } = userFormField({
    name,
    rules,
    defaultValue,
    parse,
    format,
    control,
  });

  return (
    <MuiTextField
      {...props}
      {...fieldProps}
      required={!!rules?.required}
      value={fieldProps.value ?? ''}
      inputRef={ref}
      error={invalid}
    />
  );
};
