import React, { ComponentProps, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { TextField } from './TextField';
import { FormFieldProps } from '../types';

export const PasswordField = ({
  InputProps,
  ...props
}: FormFieldProps & ComponentProps<typeof TextField>) => {
  const [showText, setShowText] = useState(false);

  return (
    <TextField
      type={showText ? 'text' : 'password'}
      {...props}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="Toggle password visibility"
              onClick={() => {
                setShowText(!showText);
              }}
            >
              {showText ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
        ...InputProps,
      }}
    />
  );
};
